<template>
  <div ref="root" class="explore-product" @click="$emit('click', $event)">
    <a class="explore-product__link" :href="externalLink" @click='productClick'>
      <div class="feature-image-section">
        <FeatureImage :url="featureImageUrl" :inactive="inactive"/>
        <ProductCardHero :src="heroImageUrl" />
      </div>

      <div class="product__detail">
        <div class="product__country">
          <span>
            {{ country }}
          </span>
          <span v-if="reference">
            ID: {{ reference }}
          </span>
        </div>
        <div class="product__title">
          {{ title }}
        </div>
        <slot name="productMeta"/>
        <slot name="detailFooter"/>
      </div>
      <div class="product__tags">
        <div class="product__spots-left" v-if="showSpotsLeft">
          {{ t('spots_left', spotsLeft, { count: spotsLeft }) }}
        </div>
        <div class="product__mba-exclusive" v-if="isMba">
          {{ t('mba_trip') }}
        </div>
      </div>
      <div class="product__right-tags">
        <div class="product__cancelled" v-if="status">
          {{ t(status) }}
        </div>
      </div>
    </a>
  </div>
</template>

<script setup>
import { isEmpty } from 'lodash';
import { trackClick, trackImpression, isInViewport } from '@/utils/eec.js'
import { blankHref } from '@/utils/stringUtils.js';
import { ref, computed, onBeforeUnmount, onMounted } from 'vue';
import { useShared } from "@/helpers/vueHelpers.js";
import FeatureImage from "./FeatureImage.vue"
import ProductCardHero from "@/modules/Explore/Product2/ProductCardHero.vue";

const root = ref(null)
const { t } = useShared();

const props = defineProps({
  featureImageUrl: String,
  heroImageUrl: String,
  country: String,
  title: String,
  isMba: { type: Boolean, default: false },
  spotsLeft: { type: Number, default: undefined },
  position: Number,
  trackingHash: { type: Object, default: () => ({}) },
  status: String,
  externalLink: { type: String, required: true },
  reference: { type: [Number, String], default: null },
  product: Object
});

defineEmits(['click']);

onMounted(() => {
  trackProductImpression();
  window.addEventListener('scroll', trackProductImpression);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', trackProductImpression);
});

const inactive = computed(() => props.status == "cancelled" || props.status == "payment_error");
const showSpotsLeft = computed(() => props.spotsLeft === null ? false : props.spotsLeft >= 0);

function productClick(evt) {
  if (isEmpty(props.trackingHash)) return;
  if (blankHref(props.externalLink)) {
    trackClick(props.trackingHash, null, evt);
  }
}

let impressionTracked = false;

function trackProductImpression() {
  if (isEmpty(props.trackingHash)) return;
  if (!impressionTracked && isInViewport(root.value)) {
    trackImpression(props.trackingHash);
    impressionTracked = true;
  }
}
</script>

<style lang="scss" scoped>

@mixin tag {
  text-transform: uppercase;
  background-color: $white;
  border-radius: $spacing-1;
  padding: $spacing-1 $spacing-2;
  font-size: $text-xs;
  font-weight: $font-bold;
  width: min-content;
  white-space: nowrap;
}

.explore-product {
  cursor: pointer;
  overflow: hidden;
  font-weight: $font-semibold;
  padding: 16px;
  border-radius: 15px;
  border: 1px solid $grey-25;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(164, 164, 164, 0.10);

  &:hover {
    border-radius: 15px;
    border: 1px solid $grey-25;
    background: $green-50;
    box-shadow: 0 4px 12px 0 rgba(164, 164, 164, 0.10);
  }

  &, a {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 16px;
  }

  a:hover {
    color: unset;
  }
}

.feature-image-section {
  position: relative;
}

.product__country {
  color: $gray;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}

.product__detail {
  flex-grow: 1;
  display: grid;
  row-gap: $spacing-2;
  align-content: start;
}

.product__tags {
  position: absolute;
  top: $spacing-3;
  left: $spacing-3;
  display: grid;
  gap: $spacing-2;
}

.product__spots-left {
  color: $brand-secondary;
  @include tag;
}

.product__mba-exclusive {
  color: $brand-primary;
  @include tag;
}

.product__right-tags {
  position: absolute;
  top: $spacing-3;
  right: $spacing-3;
  display: grid;
  gap: $spacing-2;
}

.product__cancelled {
  color: $black;
  @include tag;
}
</style>

<i18n lang="yaml">
en:
  spots_left: 'Sold out | {count} spot left | {count} spots left'
  mba_trip: MBA Trip
  cancelled: Cancelled
  payment_error: Payment error
</i18n>
